import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Hero } from "../components/Hero";
import { SearchResults as SearchResultsComponent } from "../components/SearchResults";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStore } from "react-stores";
import { searchWrapperStore } from "../stores/searchWrapperStore";
import { PropTypes } from 'prop-types'

const SearchResults = ({ data, location, pageContext }) => {
  const { t } = useTranslation();
  const searchStoreState = useStore(searchWrapperStore);
	pageContext.title = t('Zoekresultaten');
  pageContext.type = 'search-results'

  const [state] = React.useState({});
  const [query, setQuery] = React.useState(location?.href?.length ? new URL(location?.href).searchParams?.get('search') : "");

  const ttl = 60 * 60 * 1000;

  const [searchStore, setSearchStore] = React.useState(() => {
    let initialValue = null;
    if (typeof window !== "undefined") {
      initialValue = searchStoreState.store;
      if (initialValue) {
        if (typeof initialValue.expire !== "undefined" && Date.now() <= initialValue.expire) {
          initialValue = initialValue.value;
        } else {
          initialValue = null;
        }
      }
    }
    return initialValue || null;
  });
  const [searchIndex, setSearchIndex] = React.useState(() => {
    let initialValue = null;
    if (typeof window !== "undefined") {
      initialValue = searchStoreState.index;
      if (initialValue) {
        if (typeof initialValue.expire !== "undefined" && Date.now() <= initialValue.expire) {
          initialValue = initialValue.value;
        } else {
          initialValue = null;
        }
      }
    }
    return initialValue || null;
  });

  const getData = React.useCallback(async () => {
    if (searchIndex === null || searchIndex.length === 0) {
      const response = await fetch(data.localSearchPages.publicIndexURL);
      const index = await response.text();
      if (typeof window !== "undefined") {
        let state = searchWrapperStore.state;
        state.index = {
          value: index,
          expire: Date.now() + ttl,
        };
        searchWrapperStore.setState(state);
      }
      setSearchIndex(index);
    }
    if (searchStore === null || searchStore.length === 0) {
      const response2 = await fetch(data.localSearchPages.publicStoreURL);
      const store = await response2.json();
      if (typeof window !== "undefined") {
        let state = searchWrapperStore.state;
        state.store = {
          value: store,
          expire: Date.now() + ttl,
        };
        searchWrapperStore.setState(state);
      }
      setSearchStore(store);
    }
  }, [data, ttl, searchIndex, searchStore]);

  React.useEffect(() => {getData();}, [getData]);

	React.useEffect(() => {
		if (typeof window !== `undefined`) {
			window.history.pushState({}, '', `/${t('search-results')}${query.length ? '?search='+query : ""}`);
		}
	}, [query, t])

  if (location.state?.query && !state.pageLoaded)
    setQuery(location.state.query);

  state.pageLoaded = true;

  return (
    <Layout activeDocMeta={pageContext} themes={data.allPrismicTheme} hideBreadcrumb>
      <Hero title={''}>
        <div className={"o-stretch-wrapper c-search-widget__hero"}>
          <div className="c-search-widget o-wrapper">
            <div className="c-search-widget__form-block ">
              <form className="basic-form c-search-widget__form" onSubmit={(e) => e.preventDefault()}>
                <div className="form-row c-search-widget__field-column">
                  <div className="form-label">
                    <label className="c-search-widget__label" htmlFor="search">
                      <h4>{t("Waarmee kunnen we je helpen?")}</h4>
                    </label>
                  </div>
                  <div className="form-field">
                    <div className="form-field-icon field-search">
                      <div className="awesomplete c-search-widget__input">
                        <input type="search" name="Trefwoord" value={query} onInput={(e) => {setQuery(e.target.value);}}/>
                      </div>
                      <span className="field-icon icon-search" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Hero>
      
      { (searchStore && searchIndex) &&
        <SearchResultsComponent
          query={query}
          store={searchStore}
          index={searchIndex}
        />
      }
    </Layout>
  );
};

SearchResults.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object
}

export default SearchResults;

export const query = graphql`
  query SearchPageQuery($lang: String, $locale: String) {
    localSearchPages {
      publicIndexURL
      publicStoreURL
    }
    prismicSearchResults(lang: { eq: $lang }) {
      data {
        suggestions {
          search_suggestion
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
