import React from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { Link} from "gatsby";
import { useTranslation} from 'gatsby-plugin-react-i18next';
import { PropTypes } from 'prop-types';


export const SearchResults = ({query, index, store}) => {
    const {t} = useTranslation();
    const results = useFlexSearch(query, index, store)

    return (
    <div className="o-stretch-wrapper">
        <div className="o-wrapper">
            <h1 className="h1">{t("Je hebt gezocht op") + (query.length ? " (" + query + ")" : "")}</h1>
            <h2 className='h2 search-results__subtitle'>{t("Alle zoekresultaten") + (results.length ? " (" + results.length + ")" : "")}</h2>

            <div className="search-results__list">
                {results.length ?
                    results.map((result) => (
                        <div className="search-results__item" key={result.id}>
                            <h3 className="h3">
                                <Link to={result.url} state={{from: result.id}}><span className="title">{ result.title }</span></Link>
                            </h3>
                            <div className='search-results__item'>
                                <p>{result.intro}</p>
                            </div>
                        </div>
                    )) : ""
                }
            </div>
        </div>
    </div>
    );
};

SearchResults.propTypes = {
	query: PropTypes.string,
	index: PropTypes.string,
	store: PropTypes.object
}